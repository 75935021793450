.ButtonUpload {
  display: flex;
  flex-flow: wrap;
}

.Divider {
  margin-top: 7px;
  margin-bottom: 7px;
  display: block;
}
.Icon {
  font-size: 14px;
  margin: 2px 2px;
}

.Modal {
  [class="ant-modal-footer"] {
    display: flex;
  }
}

.WrapImage {
  position: relative;
}

.image {
  max-width: 100%;
}
