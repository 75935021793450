.Wrap {
  background-color: #35484e;
  flex-flow: column;
  position: relative;
  margin-bottom: 0 !important;
  overflow: hidden;
  align-items: center;
  max-height: 60vh;
  height: 100%;
  justify-content: space-between;
  opacity: 1;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
  img {
    max-width: 100%;
  }
}
.Control {
  width: 240px;
  height: 240px;
  transform: rotate(45deg);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  @media (max-width: 1420px) {
    width: 150px;
    height: 150px;
  }
}
.Side {
  background-color: #566e7a;
  width: 50%;
  height: 50%;
  color: #fff;
  border: solid 2px #35484e;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 9px 5px #35484e;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #90a3ae;
  }
  &:active {
    background-color: #90a3ae;
    transform: translate(0.2px, 1px);
  }
  i {
    transform: rotate(-45deg);
    font-size: 42px;
    font-weight: bold;
  }
}
.Side:before {
  transform: rotate(-45deg);
  display: block;
}
.SideMenu {
  width: 40%;
  height: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: #90a3ae;
  border: #35484e 4px solid;
  box-shadow: 1px 4px 7px 4px #35484e;
  cursor: pointer;
  &:hover {
    background-color: #698694;
  }
  &:active {
    background-color: #566e7a;
    transform: translate(-50%, -50.8%);
  }
}
.SideTop {
  border-radius: 100% 0 0;
  //border-left: transparent;
}
.SideRight {
  border-radius: 0 100% 0 0;
  //border-top: transparent;
}
.SideBottom {
  border-radius: 0 0 100% 0;
  //border-right: transparent;
}
.SideLeft {
  border-radius: 0 0 0 100%;
  //border-bottom: transparent;
}

.btnsBottom {
  width: 100%;
  display: flex;
  background-color: #273238;
  justify-content: space-around;
  padding: 0.4rem;
  // position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  .btnBottom {
    width: 100%;
    display: flex;
    background-color: #273238;
    justify-content: space-around;
    padding: 0.4rem;
    bottom: 0;
    left: 0;
    max-height: 55px;
    border: 0;
    align-items: center;
    color: #fff;
    &:active {
      transform: translate(0.2px, 1px);
      background-color: transparent !important;
    }
    &:disabled {
      transform: translate(0);
      color: #23282c;
      cursor: not-allowed;
    }
  }
}

.imgDiv {
  width: 100%;
  min-height: 4rem;
  flex-wrap: wrap;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  z-index: 1;
  padding: 0 .4rem;

  > [class="material-icons"] {
    font-size: 60px;
  }
}
.Progress {
  span {
    color: #fff !important;
    font-weight: bold;
  }
}
.buttonsWrapVolume {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin: .4rem 0;
  .buttonStyle {
    background-color: #566e7a;
    border-color: #566e7a;
    color: #ffffff;
  }
  button {
    min-width: inherit;
    display: flex;
    background: #566e7a;
    border: 0;
    color: #fff;
    padding: 0 10px;
    margin-right: 1rem;
  }
}

.slider {
  width: 78%;

  [class="ant-slider-track"],
  [class="ant-slider-step"],
  [class="ant-slider-rail"] {
    height: 8px;
  }
  [class="ant-slider-handle"] {
    border: solid 2px #566e7a;
  }
  [class="ant-slider-track"] {
    background-color: #566e7a;
  }
}
