.buttonsWrapper {
  float: right;
  margin-bottom: 0 !important;

  button:last-child {
    margin-left: 1.0625rem;
  }
}

.footerPadding {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.footer {
  display: flex;
  flex-flow: row-reverse;
  background: white !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  border-top: none !important;
}

.marginButton {
  margin-left: 0.5rem;
}

.cardSize {
  height: 9rem;
  margin-bottom: 0.7rem !important;
}

.addIcon {
  & i {
    font-size: 2.3rem !important;
    color: lightgrey;
    margin-left: 35%;
  }
}
.cardBody {
  margin: auto;
}

.deviceTypeDiv {
  left: -0.875rem !important;
}
.unidirectionalCol {
  margin-bottom: 1rem;
}
.checkboxInputUnidirectional {
  margin-left: 7.5rem !important;
}
