.Status {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  text-indent: -9999px;
}

.Green {
  background-color: green;
}
.Red {
  background-color: red;
}
