/* Table style override */

.table-sm tr,
.table-sm th,
.table-sm td {
  border: none;
  text-align: center;
}

.table-sm thead tr th {
  padding: 1rem .3rem;
  color: #fff;
  background: #2f353a;
  border: none;
        /* max-width: 200px; */
    /* min-width: 150px; */

}
.table-sm tbody tr td {
  padding: 1rem 0.3rem;
  vertical-align: middle;
    /* max-width: 200px; */
    /* min-width: 150px; */
}
 .table-sm tbody tr td:last-child,
  .table-sm thead tr th:last-child {
    /* max-width: 300px; */
    min-width: auto;

  }
@media (max-width: 1200px) {
  .table-sm tbody tr td,
  .table-sm thead tr th {
    max-width: 100px;
    min-width: 90px;
    font-size: 13px;
  }
  .table-sm tbody tr td:last-child,
  .table-sm thead tr th:last-child {
    max-width: 300px;
    min-width: auto;

  }

}

@media (max-width: 900px) {
  .table-sm tbody tr td,
  .table-sm thead tr th {
    font-size: 11px;

  }
}

.table-striped tbody tr,
.table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(even) {
  border: none;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.table-striped tbody tr:hover {
  background-color: rgba(32, 168, 216, 0.1);
}

td .badge {
  min-width: 70px;
  text-transform: capitalize;
}

main {
  opacity: 0; /* change opacity to 0 once activity log main block is fixed */
}

main.in {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.icon-btn {
  min-width: 28px;
}

.activity-log-operation {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 10px;
}

.activity-log-operation.created {
  background: #4dbd74;
}

.activity-log-operation.updated {
  background: #ffc107;
}

.activity-log-operation.deleted {
  background: #f93751;
}

.widget-padding {
  padding-top: 60px;
}

.widget-header-cover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-header-cover i{
  font-size: 35px;
}

.widget-header-cover.info {
  background: #63c2de;
  background: -moz-linear-gradient(left, #63c2de 0%, #b7e0eb 100%);
  background: -webkit-linear-gradient(left, #63c2de 0%, #b7e0eb 100%);
  background: linear-gradient(to right, #63c2de 0%, #b7e0eb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#63c2de', endColorstr='#B7E0EB',GradientType=1 );
}

.widget-header-cover.warning {
  background: #ffc107;
  background: -moz-linear-gradient(left, #ffc107 0%, #ecd9a1 100%);
  background: -webkit-linear-gradient(left, #ffc107 0%, #ecd9a1 100%);
  background: linear-gradient(to right, #ffc107 0%, #ecd9a1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc107', endColorstr='#ECD9A1',GradientType=1 );
}

.widget-header-cover.primary {
  background: #0fa0db;
  background: -moz-linear-gradient(left, #0fa0db 0%, #9ed2e5 100%);
  background: -webkit-linear-gradient(left, #0fa0db 0%, #9ed2e5 100%);
  background: linear-gradient(to right, #0fa0db 0%, #9ed2e5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0fa0db', endColorstr='#9ED2E5',GradientType=1 );
}

.app-header .navbar-toggler {
  margin-left: 10px;
}

.sidebar .nav-link {
  padding: 1rem;
}

.rc-pagination-item {
  min-width: 28px;
  width: auto;
  padding: 0 0.5rem;
}

.card-title {
  margin: 20px 0;
}

.card-title h1,
.card-title h2 {
  color: #2f353a;
  font-size: 1.2rem;
  font-weight: normal;
  display: inline-block;
  margin-top: 5px;
}

.card-title i {
  margin-right: 10px;
  font-size: 1rem;
}

.card-title > button,
.card-title div {
  float: right;
}

.card-title div button {
  margin-left: 15px;
}
