.loginAlert {
  margin-top: -0.9375rem;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.formTitle {
  color: black !important;
  font-weight: 500;
  font-size: 1.5625rem;
}

.resetButtonMargin {
  margin-left: -0.8125rem;
}
