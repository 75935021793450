.avatar {
  li {
    margin-right: 0.9375rem !important;

    i {
      font-size: 1.5625rem !important;
    }
  }
}

.profileDropdown {
  button {
    cursor: pointer;
  }
}

.logoImg {
  img {
    object-fit: cover;
  }
}
