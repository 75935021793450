.buttonsWrapper {
  text-align: right;

  button:not(:first-child) {
    margin-left: 1.125rem;
  }
}

.alertWrapper {
  margin-top: 0.9375rem;
}
