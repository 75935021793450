.buttonsWrapper {
  float: right;
  margin-bottom: 0 !important;

  button:last-child {
    margin-left: 1.0625rem;
  }
}

.footerPadding {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
