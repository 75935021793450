@import "../../scss/variables";

.buttonsWrapper {
  float: right;
  button:not(:first-child) {
    margin-left: 1.125rem;
  }
}

.alertWrapper {
  margin-top: 0.9375rem;
}

.contentWrapper {
  // display: flex;
  // flex-wrap: wrap;
  .detailRow {
    width: 50%;
    @media (max-width: $break-sm) {
      width: 100%;
    }
  }
  [class="row form-group"] {
    width: 100% !important;
    margin: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-transform: capitalize;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-transform: capitalize;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      text-transform: capitalize;
    }
  }
  [class="form-check-input"] {
    position: static;
    margin-top: 0.3rem;
    margin-left: 0;
  }
}

.floatButtons {
  display: flex;
  justify-content: flex-end;
  button {
    display: flex !important;
  }
}

.title {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
  font-size: 14px;
  > i {
    margin-right: 0.4rem;
  }
}

.labelSwitch {
  margin-right: 1rem;
  text-transform: capitalize;
}

.smallBox {
  width: 100%;
}
.addButton {
  display: flex !important;
  float: right;
  font-weight: bold !important;
  align-items: center;
  font-size: 14px !important;
  > i {
    margin-right: 0.3rem;
    font-size: 20px;
  }
}

.footerPadding {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.WrapTable {
  overflow: auto;
  td,
  th {
    text-align: center;
  }
}

.Table {
  //min-width: 996px;
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
}

.green {
  background-color: green;
}
.red {
  background-color: red;
}

.logoImg {
  max-width: 40px;
  display: inline-block;
}

.buttonsWrapperActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 0.2rem;
    font-size: 15px;
  }
}

.TitleAvailable {
  font-size: 18px;
  text-align: center;
  padding: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #0fa0db;
}

.fieldSwitch {
  float: right;
}

.rowGroups {
  width: 100%;
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
  }
  span {
    font-size: 10px;
  }
  .error {
    background-color: #f93751 !important;
  }
}
.messageHelper {
  display: flex;
  align-items: center;
  .reloadBbutton {
    display: flex;
    align-items: center;
    margin-left: 10px;
    i {
      font-size: 17px;
    }
  }
  small {
    height: 30px;
    display: flex;
    align-items: center;
    &.error {
      color: #f93751;
    }
  }
}
