.TextTooLong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 385px;
}

.ButtonPurple {
  background-color: var(--purple) !important;
  color: #ffffff !important;
  border-color: transparent;
}

.ButtonsActions {
  display: flex;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    margin-right: 0.3rem;
    width: 32px;
    height: 32px;
  }
  i {
    font-size: 16px;
  }
}

.Table {
  overflow-y: none;
  overflow-x: auto;

  [class="ant-select-selector"] {
    display: none;
  }
  [class="ant-pagination-options"] {
    display: none;
  }
}
