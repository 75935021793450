.loginAlert {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.formTitle {
  font-weight: 500;
  font-size: 1.5625rem;
}

.loginButtonMargin {
  margin-left: -0.8125rem;
}

.logoImg {
  width: 100%;
  justify-content: center;
  display: flex !important;
  margin-bottom: 1rem;
  img {
    object-fit: cover;
  }
}
