.recoveryBackground {
  background-color: #1b1b1b;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top; 
  background-size: cover;
  border: none !important;
  color:#ffffff;
  text-align: center;
}

.recoveryCard {
  background: none !important;
  border: none !important;
  color:#ffffff;
  text-align: center;
}

.recoveryAlert {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
  background: none;
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.formTitle {
  font-weight: 500;
  font-size: 1.5625rem;
  margin-bottom: 50px;
}

.recoveryButton {
  color:#ffffff;
  font: inherit;
  font-weight: 500;
  font-size: 1.2525rem;
  cursor: pointer;
  text-align: center;
  
  border: double 0px transparent;
  border-radius: 15px;
  width: 223px;
  height: 67px;
  outline: none;
  display: block;
  margin: 0 auto 20px;
  padding: 5px 5px;
  background-color: #2a2b2d;
  background-image: linear-gradient(#2a2b2d, #2a2b2d),  linear-gradient(to right, #0fa0db 0%, #0083c5 33%, #ec4a26 66%, #f93751 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.recoveryButton:hover {
  color:#aaaaaa;
}

.recoveryGroup {
  background-color: #2a2b2d;
  color:#ffffff;
  position: relative;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.475rem;
  font-weight: 400;
  line-height: 3;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  height: 67px;

  label {
    position: absolute;
    top: -40px;
  }

  input, :focus {
    position: absolute;
    bottom: 5px;
    left: 20px;
    width: 90%;
    background-color: #2a2b2d !important;
    color: #ffffff;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #5f6268;
  }
}

.logoImg {
  width: 100%;
  justify-content: center;
  display: flex !important;
  margin-bottom: 1rem;
  img {
    object-fit: cover;
  }
}
