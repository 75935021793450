@import "../../scss/variables";

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto !important;

  button {
    margin-right: 1.125rem;
  }
}
.filteringInput {
  margin-left: 1.25rem;
}

.filteringRow {
  align-items: center;
}

.filteringButton {
  margin-bottom: -15px;
  margin-left: 1.25rem;
}

.bottomPaginationWrapper {
  display: flex;
  margin-top: 0.9375rem;

  button {
    height: 2.1875rem;
    margin-left: auto;
  }
}

.selectAllWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 1.125rem;
  }
}

.emptyCellsRow {
  background-color: transparent !important;
  margin-top: 1rem;
  td {
    border: none !important;
  }
}

.tableFieldsWrapper {
  th {
    &:not(:last-child) {
      cursor: pointer;

      &:hover {
        color: $tableFieldsWrapperColor;
      }
    }
  }
}

.administratorsTableWrapper {
  tbody {
    td:last-child {
      border: none;
    }

    tr:not(:last-child) {
      border: 1px solid $tableBorderColor;
    }
  }
}

.uploadButton {
  margin-left: 12px;
}

.textRight {
  text-align: right;
  padding-right: 10px !important;
}
