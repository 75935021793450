.Wrap {
  background-color: #35484e !important;
  flex-flow: column;
  position: relative;
  margin-bottom: 0 !important;
  overflow: hidden;
  align-items: center;
  min-height: 700px;
  height: 100%;
  justify-content: space-between;
  img {
    max-width: 100%;
  }
}
.Control {
  width: 290px;
  height: 290px;
  transform: rotate(45deg);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  @media (max-width: 1220px) {
    width: 200px;
    height: 200px;
  }
}
.Side {
  background-color: #566e7a;
  width: 50%;
  height: 50%;
  color: #fff;
  border: solid 2px #35484e;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 9px 5px #35484e;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #90a3ae;
  }
  &:active {
    background-color: #90a3ae;
    transform: translate(0.2px, 1px);
  }
  i {
    transform: rotate(-45deg);
    font-size: 42px;
    font-weight: bold;
  }
}
.Side:before {
  transform: rotate(-45deg);
  display: block;
}
.SideMenu {
  width: 40%;
  height: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: #90a3ae;
  border: #35484e 4px solid;
  box-shadow: 1px 4px 7px 4px #35484e;
  cursor: pointer;
  &:hover {
    background-color: #698694;
  }
  &:active {
    background-color: #566e7a;
    transform: translate(-50%, -50.8%);
  }
}
.SideTop {
  border-radius: 100% 0 0;
  //border-left: transparent;
}
.SideRight {
  border-radius: 0 100% 0 0;
  //border-top: transparent;
}
.SideBottom {
  border-radius: 0 0 100% 0;
  //border-right: transparent;
}
.SideLeft {
  border-radius: 0 0 0 100%;
  //border-bottom: transparent;
}

.btnsBottom {
  width: 100%;
  display: flex;
  background-color: #273238;
  justify-content: space-around;
  padding: 0.4rem;
  // position: absolute;
  bottom: 0;
  left: 0;
  max-height: 55px;
  height: 55px;
  align-items: center;
  .btnBottom {
    border: 0;
    background-color: transparent !important;
    max-width: 50px;
    cursor: pointer;
    outline: none !important;
    border: 0 !important;
    color: #fff !important;
    display: flex;
    &:active {
      transform: translate(0.2px, 1px);
      background-color: transparent !important;
    }
    &:disabled {
      transform: translate(0);
      color: #23282c;
      cursor: not-allowed;
    }
  }
}

.imgDiv {
  width: 80%;
  min-height: 6rem;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  z-index: 1;
  margin-top: 1.5rem;
  .ProgressWrap {
    width: 80%;
  }

  > [class="material-icons"] {
    font-size: 60px;
  }
}

.buttonsWrapVolume {
  display: flex;
  width: 80%;
  justify-content: space-between;
  .buttonStyle {
    background-color: #566e7a;
    border-color: #566e7a;
    color: #ffffff;
  }
  > button {
    min-width: inherit;
    display: flex;
  }
}
