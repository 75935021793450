
.filteringInput {
  margin-left: 1.25rem;
}

.filteringButton {
  margin-top: 12px !important;
  margin-left: 1.25rem;
}

.filteringRow {
  align-items: center;
}
