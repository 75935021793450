@import "../../scss/variables";

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 1.125rem;
    font-size: 15px;
  }
}

.bottomPaginationWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  button {
    height: 2.1875rem;
    margin-left: auto;
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 28px;
    padding: 0 1rem;
    border: solid #d9d9d9 1px;
    border-radius: 6px;

    p {
      margin: 0;
      color: #666;
    }
  }
  @media (max-width: $break-md) {
    display: block;

    .total {
      display: inline-flex;
      max-width: 150px;
      height: auto;
      margin-bottom: 1rem;
    }
  }
}

.thTable {
  text-transform: capitalize;
  user-select: none;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.detailRow {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  @media (max-width: $break-sm) {
    width: 100%;
  }
  p {
    width: 50%;
  }

  strong {
    margin-right: 4px;
    text-transform: capitalize;
  }
}

.wrapActionButtons {
  display: flex;
  justify-content: center;
  width: 100%;
}
