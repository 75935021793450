.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-right: 18px;
  }
}

.bottomPaginationWrapper {
  margin-top: 15px;
  display: flex;
  button {
    margin-left: auto;
    height: 35px;
  }
}

.selectAllWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-right: 18px;
  }
}

.emptyCellsRow {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
  td {
    border: none !important;
    
  }
}

.administratorsTableWrapper {
  tbody {
    tr:not(:last-child) {
      cursor: pointer;
    }
  }
}

.operatorsTableWrapper {
  tbody {
    td:last-child {
      border: none;
    }
    tr:not(:last-child) {
      border: 1px solid #c8ced3;
    }
  }
}

.filteringInput {
  margin-left: 1.25rem;
}

.filteringRow {
  align-items: center;
}

.filteringButton {
  margin-bottom: -15px;
  margin-left: 1.25rem;
}

.autosuggestWrapper {
  position: relative;
  input:focus + div {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  input + div {
    width: 100%;
    position: absolute;
    z-index: 1;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //top: calc(100% + 2px);

    ul {
      list-style: none;
      padding: 10px 0;
      margin: 0;

      li {
        padding: 2px;
        padding-left: 15px;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
}
