@import "../../../scss/variables";

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 1.125rem;
  }
}

.bottomPaginationWrapper {
  display: flex;
  margin-top: 0.9375rem;

  button {
    height: 2.1875rem;
    margin-left: auto;
  }
}

.selectAllWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 1.125rem;
  }
}

.emptyCellsRow {
  background-color: transparent !important;

  td {
    border: none !important;
  }
}

.tableFieldsWrapper {
  th {
    &:not(:last-child) {
      cursor: pointer;

      &:hover {
        color: $tableFieldsWrapperColor;
      }
    }
  }
}

.administratorsTableWrapper {
  tbody {
    td:last-child {
      border: none;
    }

    tr:not(:last-child) {
      border: 1px solid $tableBorderColor;
    }
  }
}

.filteringInput {
  margin-left: 1.25rem;
}

.filteringRow {
  align-items: center;
}

.filteringButton {
  margin-bottom: -15px;
  margin-left: 1.25rem;
}

.checkboxFilteringInput {
  margin-top: 40px !important;
  margin-left: -5rem !important;
}

.romCheckboxFiltering {
  margin-bottom: 2.5rem !important;
}

.installedAppText {
  font-size: 1.1rem;
  margin-top: 1.5rem;
  font-weight: 500;
}

.ButtonStatus {
  float: right;
  display: flex !important;
  margin: 1rem 0 0.4rem 1rem;
  z-index: 2;
  position: relative;
}

.ButtonDelete {
  display: flex !important;
  &:first-child {
    margin-right: 0.5rem;
  }
  i {
    font-size: 16px;
  }
}

.TabContent {
  min-height: 20rem;
  position: relative;
  .TabPane {
    padding: 0 !important;
  }
}

.progressCircle {
  text-align: center;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
}

.active {
  font-weight: bold;
}
