@import "../../scss/variables";

.buttonsWrapper {
  text-align: right;

  button:not(:first-child) {
    margin-left: 1.125rem;
  }
}

.alertWrapper {
  margin-top: 0.9375rem;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  .detailRow {
    width: 50%;
    display: flex;
    @media (max-width: $break-sm) {
      width: 100%;
    }
    > div {
      width: 100%;
    }
    strong {
      width: 30%;
      text-align: right;
    }
    span {
      width: 70%;
    }
  }
}
