.buttonsWrapper {
  float: right;
  margin-bottom: 0 !important;

  button:last-child {
    margin-left: 1.0625rem;
  }
}

.footerPadding {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.footer {
  display: flex;
  flex-flow: row-reverse;
  background: white !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  border-top: none !important;
}

.marginButton {
  margin-left: 0.5rem;
}

.cardSize {
  height: 9rem;
  margin-bottom: 0.7rem !important;
}

.addIcon {
  & i {
    font-size: 2.3rem !important;
    color: lightgrey;
    margin-left: 35%;
  }
}
.cardBody {
  margin: auto;
}

.deviceTypeDiv {
  left: -0.875rem !important;
}

.checkboxInput {
  margin-left: -5.1rem !important;
  margin-top: 2.3rem !important;
}

.checkboxInputWipeData {
  margin-left: -4rem !important;
  margin-top: 2.3rem !important;
}

.wipeDataCol {
  display: flex;
  justify-content: center;
  flex-flow: column;
  > div {
    margin-bottom: 0;
  }
  label {
    margin-right: 2px;
  }
  input[type='checkbox'] {
    position: relative;
    top: .1rem;
    left: 1rem;
    margin: 0 !important;
  }

}

.TitleAvailable {
  overflow: hidden;

  > div {
    position: relative;
    z-index: 1;
    width: 110%;
    margin-left: -15px;
    margin-bottom: 1rem;
  }
}
