.buttonsWrapper {
  text-align: right;

  button:not(:first-child) {
    margin-left: 18px;
  }
}

.alertWrapper {
  margin-top: 15px;
}
