@import "../../scss/variables";
@import "../../scss/variables";

.buttonsWrapper {
  text-align: right;

  button:not(:first-child) {
    margin-left: 1.125rem;
  }
}

.alertWrapper {
  margin-top: 0.9375rem;
}

.contentWrapper {
  // display: flex;
  // flex-wrap: wrap;
  .detailRow {
    width: 50%;
    @media (max-width: $break-sm) {
      width: 100%;
    }
  }
  [class="row form-group"] {
    width: 100% !important;
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-transform: capitalize;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-transform: capitalize;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      text-transform: capitalize;
    }
  }
  [class="form-check-input"] {
    position: static;
    margin-top: 0.3rem;
    margin-left: 0;
  }
}

.floatButtons {
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
  font-size: 14px;
  > i {
    margin-right: 0.4rem;
  }
}

.labelSwitchWrap {
  width: 100%;
  display: flex;
  > div {
    padding-left: 0;
    padding-right: 0;
  }
}
.labelSwitch {
  margin-right: 1rem;
  min-width: 53%;
  margin-bottom: 0;
  &::first-letter {
    text-transform: uppercase;
  }
}

.smallBox {
  width: 100%;
}
.addButton {
  display: flex !important;
  float: right;
  font-weight: bold !important;
  align-items: center;
  font-size: 14px !important;
  > i {
    margin-right: 0.3rem;
    font-size: 20px;
  }
}

.footerPadding {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.WrapTable {
  overflow: auto;
  td,
  th {
    text-align: center;
  }
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
}

.green {
  background-color: green;
}
.red {
  background-color: red;
}

.logoImg {
  max-width: 40px;
  display: inline-block;
}

.buttonsWrapperActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 0.2rem;
    font-size: 15px;
  }
}

.TitleAvailable {
  overflow: hidden;

  > div {
    position: relative;
    z-index: 1;
    width: 110%;
    margin-left: -15px;
    margin-bottom: 1rem;
  }
}

.fieldSwitch {
  float: right;
}

.logo {
  max-width: 250px;
  width: 100%;
  height: auto;
  float: left;
}
