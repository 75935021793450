.unlockAlert {
  margin-left: -0.93rem;
  margin-right: -0.93rem;
}

.unlockTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: black;
  text-align: center;
}
