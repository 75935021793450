@import "../../scss/variables";

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  .detailRow {
    width: 50%;
    display: flex;
    @media (max-width: $break-sm) {
      width: 100%;
    }
    > div {
      width: 100%;
    }
    strong {
      width: 30%;
      text-align: right;
    }
    span {
      width: 70%;
    }
  }
}

.rowBuilds {
  border-top: 1px solid #0fa0db;
  padding-top: 1rem;
}
