@import "../../scss/variables";

.buttonStyle {
  // min-width: 8.1rem;
  margin-bottom: -8.5px;
  display: block;
}

.actionsPlaceholder {
  display: flex;
  margin-bottom: 2rem;
}

.actionPlaceholder {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 4%;
  justify-items: stretch;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &:last-child {
    margin-left: auto !important;
  }
}

.headerText {
  display: flex;
  margin-top: 1.9rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.rowClass {
  margin-bottom: 3.1rem;
  align-items: center;
  button {
    margin: 1rem 0;
  }
  p {
    @media (min-width: $break-xl) {
      text-align: right;
    }
  }
  .TurnOffButton {
    display: flex !important;
    float: right;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    i {
      margin-right: 0.3rem;
    }
  }
}
.buttonWrap {
  padding: 2rem 0;
}

.progressCircle {
  margin-top: 18rem;
  text-align: center;
}
.remoteButtonsDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.backButton {
  flex-grow: 1;
  margin-right: 5px;
}

.appsButton {
  width: 100%;
}

.installedAppText {
  font-size: 1.1rem;
  margin-top: 1.5rem;
}

.screenshot-modal {
  min-width: 760px;
  img {
    width: 100%;
  }
}

.closedSession {
  pointer-events: none;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(7px);
  background-color: transparent;
}

.button {
  width: 100%;
  margin-top: 0.4rem;
}

.SpinnerFloater {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ImgWrap {
  position: relative;
}

.CommandsWraps {
  position: relative;
  width: 100%;
}

.LayerWaiting {
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  z-index: 1021;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-end;
  flex-flow: column;
  text-align: center;
  color: #fff;
  align-items: center;
  left: 0;
  .buttonStyle {
    display: flex;
    font-size: 21px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    i {
      margin-right: 0.2rem;
    }
  }
  .progressCircle {
    margin: 6rem;
    text-align: center;
  }
}

.WrapButtonsWaitScreen {
  width: 100%;
  max-width: 370px;
  display: flex;
  justify-content: center;
}
.WrapButtonsWaitScreenOff {
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  align-items: center;
}
