.Floating {
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 1030;
  cursor: move;
  min-width: 360px;

  > [class="wrap"] {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    > div {
      margin-top: 1rem;
      button {
        display: flex !important;
        padding: 0.2rem;
        margin: 0.4rem;
      }
    }
  }
  img {
    max-width: 100%;
    pointer-events: none;
    cursor: move;
  }

  .wrapImageFloaring {
    position: relative;
  }
  .wrapButtonsFloater {
    display: flex;
    margin-top: 0.4rem;
    button {
      display: flex;
      margin-right: 0.4rem;
    }
  }
  .SpinnerFloater {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .BtnClose {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    background-color: transparent;
    border: 0;
    color: #fff;

    i {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
