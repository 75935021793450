.WrapHelpersButtons {
  display: flex;
  padding: 0.6rem;
  flex-flow: wrap;
  justify-content: space-evenly;
  text-align: center;
  bottom: 0;
  left: 0;
  background-color: #273238;
  align-items: center;
  width: 100%;

  &.Top {
    top: 0;
    bottom: auto;
    button {
      cursor: inherit;
    }
  }
  &.disabled {
    display: none !important;
  }
  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    background-color: #566e7a;
    border-color: #566e7a;
    color: #ffffff;
    font-size: 14px;
    &:focus,
    &:active {
      background-color: #566e7a !important;
      border-color: #566e7a !important;
      color: #ffffff !important;
    }
    i,
    span {
      font-size: 16px;
      line-height: 0 !important;
    }
  }
}

.screenshotCard {
  display: flex;
  margin-bottom: 2rem;
  &.wrapImg {
    display: flex;
    flex-flow: column;
    align-self: center;
    width: 100%;
    border: 0.06rem solid lightgrey;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
  [class="ant-spin-nested-loading"] {
    width: 100%;
  }
  p {
    margin-top: -2.5rem;
  }
  a,
  img {
    max-width: 100%;
    align-self: center;
  }
}

.SpinnerFloater {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ImgWrap {
  text-align: center;
  img {
    opacity: 0.5;
  }
}

.loadingWrap {
  width: 100%;
}

.reloadButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  background-color: #566e7a;
  border-color: #566e7a;
  color: #ffffff;
  font-size: 14px;
  i,
  span {
    font-size: 16px;
  }
  &:focus,
  &:active {
    background-color: #566e7a !important;
    border-color: #566e7a !important;
    color: #ffffff !important;
  }
}
