@import "../../scss/variables";

.wrapper {
  display: flex;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  .detailRow {
    width: 50%;
    display: flex;
    @media (max-width: $break-sm) {
      width: 100%;
    }
    > div {
      width: 100%;
    }
    strong {
      width: 30%;
      text-align: right;
    }
    span {
      width: 70%;
    }
  }
}
.imageRow {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $break-sm) {
    width: 100%;
  }
  > div {
    width: 100%;
    justify-content: center;
  }
  strong {
    width: 30%;
    text-align: right;
  }
  span {
    width: 70%;
  }
  figure {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
    max-height: 186px;
  }
}
