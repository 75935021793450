.WrapHelpersButtons {
  display: flex;
  padding: 0.3rem;
  flex-flow: wrap;
  justify-content: space-evenly;
  text-align: center;
  bottom: 0;
  left: 0;
  background-color: #273238;
  align-items: center;
  width: 100%;
  @media (min-width: 1400px) {
    height: 55px;
  }

  &.Top {
    top: 0;
    bottom: auto;
  }
  &.disabled {
    display: none !important;
  }
  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    background-color: #566e7a;
    border-color: #566e7a;
    color: #ffffff;
    font-size: 14px;
    @media (max-width: 1120px) {
      span {
        display: none;
      }
    }
    > i {
      // margin-right: 3px;
      font-size: 15px;
    }
    @media (min-width: 1320px) {
      font-size: 15px;
      i {
        // margin-right: 4px;
        font-size: 21px;
      }
    }
  }
}

.screenshotCard {
  display: flex;
  margin-bottom: 2rem;
  &.wrapImg {
    display: flex;
    flex-flow: column;
    align-self: center;
    width: 100%;
    border: 0.06rem solid lightgrey;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    // padding: 60px 1rem;
  }
  p {
    margin-top: -2.5rem;
  }
  a,
  img {
    max-width: 100%;
    align-self: center;
  }
}

.SpinnerFloater {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}